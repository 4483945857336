import React from 'react'

function Services() {

    const data = [
        {
            heading: "Residential Interior Design",
            description: "We blend aesthetics with functionality, creating spaces that reflect your style and enhance your lifestyle.",
            img: "https://i.postimg.cc/Bvw7cx3r/image-1.jpg"
        },
        {
            heading: "EXTERIOR",
            description: "Structural layout, Floor plans, working elevations and 3D views.Transform your property's first impression with our exterior architecture expertise. ",
            img: "https://i.postimg.cc/zvTdr6hN/image-2.jpg"
        },
        {
            heading: "Commercial Space Planning",
            description: "From optimizing layouts for efficiency to creating a welcoming atmosphere, we tailor our designs to meet the unique needs of your business.",
            img: "https://i.postimg.cc/RVdmLz5t/image-3.png"

        },
        {
            heading: "Architectural Consultation and Planning",
            description: "Whether you're building a new home or commercial space, we provide expert guidance in conceptualizing, designing, and planning every architectural detail.",
            img: "https://i.postimg.cc/T3HXBqPY/image-4.png "
        },
        {
            heading: "Renovation and Remodeling",
            description: "Breathe new life into your space. We specialize in revitalizing existing structures, optimizing layouts, and upgrading features to meet modern standards while preserving the essence of the original design.",
            img: "https://i.postimg.cc/g20WMBmT/image-5.png"
        },

    ]

  return (
    <>
        <div id='services' className='flex justify-center flex-col md:mt-28' >
            <h1  className=' md:ml-14 md:text-5xl text-4xl text-center md:text-start font-extralight' >SERVICES</h1>
            <div className='m-16 '>

                {
                    data.map((d,index) => {
                        
                        if(index%2 === 0){
                            return (
                                <div key={index} className='pb-20 md:mb-16 flex flex-col md:flex-row md:justify-between ' >
                                    <img className='w-[24rem]' src={d.img} alt=""  />
                                    
                                    <div className='md:w-1/2'>
                                        <h2 data-aos="fade-up" data-aos-duration="1000"  className='md:text-4xl text-2xl font-extralight text-zinc-600' >{d.heading}</h2>
                                        <p data-aos="fade-up" data-aos-duration="1300"  className='md:text-2xl text-lg font-extralight mt-3 md:pr-14 text-zinc-500' >{d.description}</p>
                                    </div>
                                </div>
                            )
                        } else {
                            return (
                                <div key={index} className='pb-20 md:mb-16 flex flex-col md:flex-row md:justify-between' >
                                    <div className='md:w-1/2'>
                                        <h2 data-aos="fade-up" data-aos-duration="1000"  className='md:text-4xl text-2xl font-extralight text-zinc-600' >{d.heading}</h2>
                                        <p data-aos="fade-up" data-aos-duration="1300"  className='md:text-2xl text-lg font-extralight mt-3 md:pr-14 text-zinc-500' >{d.description}</p>
                                    </div>

                                    <img className='w-[24rem]' src={d.img} alt="" />
                                    
                                </div>
                            )
                        }
                        
                        
                    })
                }

            </div>
        </div>
    </>
  )
}

export default Services