import React from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css'; 


function About() {


  AOS.init();

  return (
    <>
        
        <div id="about" className='flex md:flex-row flex-col md:justify-between md:my-36 mt-36'>

            <img className='w-[30rem]' src="./assets/about.svg" alt="" />

            <div className='md:mx-20'>
                <h1 data-aos="fade-up" data-aos-duration="1000" className='md:text-5xl text-4xl font-extralight md:text-start text-center md:my-0 my-7'>ABOUT US</h1>
                <h3 data-aos="fade-up" data-aos-duration="1500" className='md:text-4xl text-2xl font-extralight md:mt-9 mb-9 px-7 md:px-0 text-center md:text-start md:mb-0 md:mr-11 text-zinc-600'>
                    We are the team of interior designers, planners and architects We design complete environment - Interior and Exterior.
                </h3>
            </div>
        </div>
    </>
  )
}

export default About