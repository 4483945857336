import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import Hamburger from 'hamburger-react'
import { Link } from 'react-router-dom';

import logo from './assets/logoE.png'

const Nav = styled.nav`
        min-height: 10vh;
        color: black;
        padding-left: 3rem;
        display: flex;
        align-items: center;


        @media(max-width: 700px){
          padding-left: 1rem;
    
          h2 {
            position: absolute;
            top: 1rem;
            left: 1rem;
          }
        }
`;

const Links = styled.div`
        display: flex;
        align-items: center;
        justify-content: right;
        width:  100%;
        margin-right: 2rem;
        
        a{
          text-decoration: none;
          font-size: 1.2rem;
        }

        @media (max-width: 700px){
           position: absolute;
           top:0;
           left:0;
           flex-direction: column;
           justify-content: center;
           align-items: center;
           background-color: white;
           height: 90vh;
           
          a {
            font-size: 2.5rem;
            margin-bottom: 2rem;
          }
        }

    `;

const Bars = styled.div`
        display: none;


        @media(max-width: 700px){
          display: block;
          position: absolute;
          top: 0.6rem;
          right: 1rem;
          cursor: pointer;
        }
`;

function BlogNavBar() {

  const [isOpen, setOpen] = useState(false)

  const [toggleMenu, setToggleMenu] = useState(false);
  const toggleNav = () => {
    setToggleMenu(!toggleMenu)
  }
  const [screenWidth, setScreenWidth] = useState(window.innerWidth)
  
  useEffect(() => {
    const changeWidth = () => {
      setScreenWidth(window.innerWidth);
    }
    window.addEventListener('resize', changeWidth)
    return () => {
      window.removeEventListener('resize', changeWidth)
    }
  }, [])


  return (
    <>
      <Nav>
          <Link to='/'>
            <img className="md:h-36 h-20 my-1" src={logo} alt="Logo" />
          </Link>

          {(toggleMenu || screenWidth > 700) && (
            <Links className='topnav' id='myTopnav'>
                <Link to='/' className='font-semibold md:mx-4 hover:text-blue-600'>Home</Link>
                <Link to='/projects' className='font-semibold md:mx-4 hover:text-blue-600'>Projects</Link>
                <Link to="/blog" className='font-semibold md:mx-4 hover:text-blue-600' >Articles</Link>
            </Links>
          )}


          <Bars onClick={toggleNav}>
                <Hamburger toggled={isOpen} toggle={setOpen} size={24} />
          </Bars>

      </Nav>
    </>
  )
}

export default BlogNavBar