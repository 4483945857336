import React from 'react'
import { useState, useEffect } from 'react';
import {Link} from 'react-router-dom'
import sanityClient from '../sanityClient';

import Swal from 'sweetalert2';
import emailjs from '@emailjs/browser';


const SERVICE_ID = "service_56896j3";
const TEMPLATE_ID = "template_f5phyrp";
const USER_ID = "hQydbHNDbf5aa4oa5";

function Projects() {

  const [imgs, setImgs] = useState([]);

  useEffect(() => {
    sanityClient.fetch(
        `*[_type == "gallery"][0..3] {
            caption,
            slug,
            attribution,
            img {
                asset -> {
                    _id,
                    url
                },
                alt
            }
        }`
    ).then((data) => setImgs(data)).catch(
        console.error()
    )
  }, [])

  const handleOnSubmit = (e) => {
    e.preventDefault();
    emailjs.sendForm(SERVICE_ID, TEMPLATE_ID, e.target, USER_ID)
      .then((result) => {
        console.log(result.text);
        Swal.fire({
          icon: "success",
          title: "Message Sent Successfully"
        })
      }, (error) => {
        console.log(error.text);
        Swal.fire({
          icon: "error",
          title: "Ooops, something went wrong",
          text: error.text,
        })
      });
    e.target.reset()
  };



  return (
    <div id='project' className='mt-11 mb-12 flex flex-col justify-center items-center'>
        <h1 className='md:text-5xl text-4xl text-center md:text-start font-extralight md:ml-14' >OUR WORK</h1>
       
        <div className='grid md:grid-cols-2 grid-cols-1 md:gap-16 gap-5 py-8 mx-11 '>
            {imgs.map((img) => (
              <article key={img.slug.current} className='flex flex-col justify-center items-center'>
                <img className="rounded-sm md:mb-5 md:h-[15rem] h-[11rem] w-full hover:scale-105 overflow-hidden transition-all" src={img.img.asset.url} alt={img.caption} />
                {/* <h4 className="text-lg my-2 mx-3 font-medium text-center text-zinc-800 hover:text-zinc-400">{img.caption}</h4> */}
              </article>
            ))}
        </div>
        <Link to="/projects" className="py-2 px-6 rounded-md  text-white bg-[#000] hover:bg-[#fff] hover:text-[#000] border hover:border-black text-lg font-light">See More</Link>


        {/* Contact form */}
        <form className=' md:w-1/3 w-full px-5 md:my-24 my-14'  onSubmit={handleOnSubmit} >
          <h2 className="text-center font-light text-3xl">Message us</h2>
          <label className='text-lg block pb-1 font-extralight' htmlFor="name" >Name : </label>
          <input required id='name' name='user_name' className='border border-zinc-300 rounded-md px-2 py-1 mb-5 outline-none w-full' type="text" />

          <label className='text-lg block pb-1 font-extralight' htmlFor="email" >Email : </label>
          <input id='email' name='user_email' required className='border border-zinc-300 rounded-md px-2 py-1 mb-5 outline-none w-full' type="email" />

          <label className='text-lg block pb-1 font-extralight' htmlFor="message" >Message : </label>
          <textarea required className='border border-zinc-300 w-full rounded-md px-2 py-1 outline-none' name="user_message" id="message" cols="27" rows="3"></textarea>
          <button className='bg-[#000] hover:bg-zinc-800 flex items-center justify-center text-xl font-light rounded-md text-white mt-4 py-2 px-4 w-full' type="submit">SEND</button>
        </form>
    </div>
  )
}

export default Projects