import React from 'react'
import About from "../components/About";
import Contact from "../components/Contact";
import Hero from "../components/Hero";
import NavBar from "../components/NavBar";
import Projects from "../components/Projects";
import Services from "../components/Services";
import Model from '../components/Model';

const Home = () => {
  return (
    <>
      <NavBar/>
      <Hero/>
      <About/>
      <Model/>
      <Services/>
      <Projects/>
      {/* <Testimonials/> */}
      <Contact/>
    </>
  )
}

export default Home