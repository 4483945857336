import React, { useEffect, useState } from 'react'

import { Canvas, useThree } from "@react-three/fiber";
import { useGLTF, Stage, PresentationControls } from "@react-three/drei";

function ModelThreeD(props){

  const {scene} = useGLTF("./assets/model.glb");

  return (
    <primitive object={scene} {...props} />
  )
}

const CameraControls = ({ zoom }) => {
  const { camera } = useThree();

  useEffect(() => {
    camera.zoom = zoom;
    camera.updateProjectionMatrix();
  }, [zoom, camera]);

  return null;
};

const Model = () => {

  const [zoom, setZoom] = useState(0.5);

  const handleZoomIn = () => {
    setZoom((prevZoom) => Math.min(prevZoom + 0.1, 2)); // Set a max zoom level
  };

  const handleZoomOut = () => {
    setZoom((prevZoom) => Math.max(prevZoom - 0.1, 0.1)); // Set a min zoom level
  };

  
  return (
    <div className='md:min-h-[90vh]'>
        <p className='text-center  md:text-2xl text-lg font-thin py-11 md:px-20 px-5'>Dive into the next level of home visualization - where your vision comes to life, one click at a time. Explore, engage, and experience your future home in a revolutionary way.</p>
        <h4 className='text-center font-medium py-6'>Interact With Mouse/touch</h4>
          <div className='md:h-[800px] h-[250px]'>
            <div className="controls flex justify-center items-center w-full gap-6">
              <button onClick={handleZoomIn} className='text-3xl bg-blue-400 px-5 text-white rounded-md'>+</button>
              <button onClick={handleZoomOut} className='text-3xl bg-blue-400 px-5 text-white rounded-md'>-</button>
            </div>
            <Canvas  className='touch-none' dpr={[1,2]} shadows camera={{fov: 45, zoom: 1}} >
                <color attach="background" args={["#fff"]} />
                <CameraControls zoom={zoom} />
                <PresentationControls 
                    speed={1.5} 
                    global 
                    zoom={1} 
                    polar={[-0.1, Math.PI/4]}
                >
                    <Stage environment={null} shadows={false} >
                        <ModelThreeD scale={0.01} />
                    </Stage>
                </PresentationControls>
            </Canvas>
            
          </div>


    </div>
  )
}

export default Model