import client from '@sanity/client';

const sanityClient = client({
    projectId: "j9hr7ou5",
    dataset: "production",
    useCdn: true,
    apiVersion: "2023-03-06"
})

export default sanityClient;
