import React from 'react'
import { useState, useEffect } from 'react';
import NavBar from '../components/NavBar.jsx'
import sanityClient from '../sanityClient';

const Gallery = () => {

  const [imgs, setImgs] = useState([]);

  const [selectedImg, setSelectedImg] = useState(null);

  const openModal = (img) => {
    setSelectedImg(img);
  };

  const closeModal = () => {
    setSelectedImg(null);
  };

  useEffect(() => {
    sanityClient.fetch(
        `*[_type == "gallery"] {
            caption,
            slug,
            attribution,
            img {
                asset -> {
                    _id,
                    url
                },
                alt
            }
        }`
    ).then((data) => setImgs(data)).catch(
        console.error()
    )
  }, [])

  return (
    <div className=''>
        <NavBar/>
        <div className=''>
            <h1 className='text-center text-3xl md:text-5xl text-zinc-400 font-semibold md:my-11 my-8'>Our Projects</h1>

            <div className='p-11 grid grid-cols-1 gap-x-16 gap-y-16 md:grid-cols-2 lg:grid-cols-3'>
                {imgs.map((img) => (
                    <article key={img.slug.current} className='relative cursor-pointer' >
                        <img className='rounded-sm w-full h-full object-cover' src={img.img.asset.url} alt={img.caption} onClick={() => openModal(img)} />
                        <h4 className='text-xl mt-2 absolute bottom-0 left-0 bg-black bg-opacity-50 text-white p-2 w-full'>{img.title}</h4>
                    </article>
                ))}
            </div>
        </div>
        
        {selectedImg && (
                <div className='fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50' onClick={closeModal}>
                    <div className='relative'>
                        <img className='rounded-sm max-w-full max-h-screen' src={selectedImg.img.asset.url} alt={selectedImg.caption} />
                        <button className='absolute top-2 right-2 text-white text-2xl' onClick={closeModal}>&times;</button>
                    </div>
                </div>
        )}

    </div>
  )
}

export default Gallery