import Gallery from "./pages/Gallery";
import Home from "./pages/Home";
import { Route, Routes } from 'react-router-dom';
import { Helmet } from 'react-helmet'; // For SEO meta tags
import Blog from "./pages/Blog";
import SinglePost from "./pages/SinglePost";


function App() {
  return (
    <>
      <Helmet>
        <title>Enticing Empire</title>
        <meta name="description" content="Your Interior Design and Architecture Solutions in Indore, MP, India . Architect And Interior Designer. We are here to work hard and bring your dream into reality. Interior Designer in Indore .INDORE MP INDIA"/>
        <meta name="keywords" content="interior design, architecture, Indore, MP, India, home decor"/>
        <meta name="author" content="Zeeshan Khan"/>

        {/* <!-- Geo Meta Tags --> */}
        <meta name="geo.region" content="IN-MP"/>
        <meta name="geo.placename" content="Indore"/>
        <meta name="geo.position" content="22.74454918810034, 75.79635383561423"/> 
        <meta name="ICBM" content="22.74454918810034, 75.79635383561423"/>

      </Helmet>

      <Routes>
        <Route path='/' element={<Home/>} />
        <Route path='/projects' element={<Gallery/>} />
        <Route path='/blog' element={<Blog/>} />
        <Route path='/blog/:slug' element={<SinglePost />}  />
      </Routes>
    </>
  );
}

export default App;
