import React from 'react';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import sanityClient from '../sanityClient';
import NavBar from '../components/NavBar';

const Blog = () => {

  const [posts, setPosts] = useState([]);

  useEffect(() => {
    sanityClient.fetch(
        `*[_type == "post"] {
            title,
            slug,
            body,
            mainImage {
                asset -> {
                    _id,
                    url
                },
                alt
            }
        }`
    ).then((data) => setPosts(data)).catch(
        console.error()
    )
  }, [])

 
  return (
    <div className=' ' >
        <NavBar/>
        <section className='px-10 mb-16'>
          <motion.div 
            style={{ y: 30 }} animate={{ y: 0 }}
            transition={{duration: 0.5}}
          >
            <h1 className='font-bold text-4xl md:text-5xl pt-5 mb-10 text-center' >Articles</h1>
          </motion.div>

          <div className='grid grid-cols-1 md:gap-24 gap-11 md:grid-cols-2 lg:grid-cols-3'>
            {posts.map((post) => (
                <motion.div
                  style={{ y: 30 }} animate={{ y: 0 }} 
                  transition={{duration: 0.55}}
                  key={post.slug.current}
                >
                  <Link to={`/blog/${post.slug.current}`} 
                    className='flex flex-col items-start justify-between h-full relative rounded-2xl p-6  bg-blue-50 hover:bg-blue-100 hover:text-neutral-500 transition-all delay-75 ease-in-out'
                  >
                      <img className='rounded-xl w-full md:h-[400px] h-[200px] object-cover' src={post.mainImage.asset.url} alt={post.title} />
                      <h4 className='text-xl font-medium mt-2 mb-6 '>{post.title}</h4>
                      {/* <button className='mt-5 mb-10 '>
                          <Link to={`/blog/${post.slug.current}`}  className='py-2 px-6 rounded shadow text-black bg-yellow-400 hover:bg-transparent border-2 border-black transition-all duration-500  font-bold' >Read Full Article</Link>
                      </button> */}
                  </Link>
                </motion.div>
            ))}
          </div>
        </section>
    </div>
  )
}

export default Blog