import React from 'react'
import { GrFacebook, GrInstagram } from "react-icons/gr";

function Contact() {


  return (
    <div id='contact' className='bg-black m-0 p-0 flex flex-col justify-center items-center'>
        <h1 className='md:text-5xl text-4xl font-extralight text-center py-9 text-white'>CONTACT US</h1>
        
        <div className='flex flex-col justify-center md:flex-row text-white md:py-9'>
            <div className='md:mr-36 md:text-start text-center'>
                <h1 className='md:text-3xl text-2xl font-extralight'>ZEESHAN KHAN</h1>
                <h1 className='md:text-3xl text-2xl font-extralight mt-8'>Contact Number :</h1>
                <h1 className='md:text-2xl text-xl font-extralight mt-2 text-slate-300'>+91 9301550360</h1>
                <h1 className='md:text-2xl text-xl font-extralight mt-2 text-slate-300'>+91 9977707998</h1>
                <h1 className='md:text-3xl text-2xl font-extralight mt-8'>E-mail Address :</h1>
                <h1 className='md:text-2xl text-xl font-extralight mt-2 text-slate-300'>enticingempire@gmail.com</h1>
                <h1 className='md:text-3xl text-2xl font-extralight mt-8'>FOLLOW US ON</h1>
                <div className='mt-3 flex  md:items-start md:justify-start items-center justify-center' >
                  <a className='text-3xl hover:text-primary mr-4' target='_blank' rel='noreferrer' href="https://www.facebook.com/EnticingEmpire" ><GrFacebook/></a>
                  <a className='text-3xl hover:text-primary' target='_blank' rel='noreferrer' href="https://www.instagram.com/enticingempire/" ><GrInstagram/></a>
                </div>
            </div>
            <div className="social md:text-start text-center">
                <h1 className='md:text-3xl text-2xl font-extralight '>Address :</h1>
                <h1 className='md:text-2xl text-xl font-extralight mt-2 text-slate-300'>D-43 Omkar marg gandhinagar indore,</h1>
                <h1 className='md:text-2xl text-xl font-extralight mt-2 text-slate-300'>452001 Indore, Madhya Pradesh, India</h1>
                <div className='flex justify-center items-center my-6'>
                  <iframe 
                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14718.770442746703!2d75.7924985!3d22.7396639!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3962fe1d75bedd6b%3A0x28fc36733ea16d0d!2sEnticing%20Empire!5e0!3m2!1sen!2sin!4v1692258693695!5m2!1sen!2sin" 
                    title='address' width="550" height="400" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"
                    className='border-0 outline-none mt-3 rounded-md  w-[90vw] md:w-full'
                  />
                </div>


            </div>
        </div>

    </div>
  )
}

export default Contact