import React from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css'; 

function Hero() {

  AOS.init();

  return (
    <>
        <div id='hero' className=' h-[67vh] flex md:justify-between justify-center items-center flex-col md:flex-row'>
            <div className=" md:ml-16 my-20 md:my-0">
                <h1 className='md:text-5xl text-center md:text-start text-3xl md:mr-[8rem] font-extralight'>Crafting Stunning Interiors & Architectural Masterpieces</h1>
                {/* <h1  className='md:text-5xl text-center md:text-start text-3xl font-extralight md:mt-3'>Interior Designer</h1> */}
                {/* <p  className='md:text-3xl text-center md:text-start text-2xl font-light md:mr-[25rem]  mt-4'>Crafting Stunning Interiors & Architectural Masterpieces</p> */}
                <p  className='md:text-xl text-center md:text-start text-2xl font-extralight text-neutral-500 md:mr-[8rem] xl:mr-[26rem]  mt-4'>Elevate your home or business environment with our bespoke interior design and architectural services. Our expert team blends creativity and functionality to create spaces that inspire and delight.</p>
                
            </div>
            <img  className='md:w-[22rem] w-[14rem] md:mr-20 md:relative md:top-16 top-24' src="./assets/hero.svg" alt="" />
        </div>
    </>
  )
}

export default Hero